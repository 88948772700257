import React, { createContext, useContext, useEffect, useState } from 'react'
import { SUPPORTED_LIST_URLS__NO_ENS } from '../constants'
import { isAddress } from '../utils'
const TokenLogosContext = createContext()

export const useTokenLogo = (address) => {
  const context = useContext(TokenLogosContext)
  return context.logos[isAddress(address)]
}
export const TokenLogosProvider = ({ children }) => {
  const [logos, setLogos] = useState({})

  const loadLogos = async () => {
    Promise.all(
      SUPPORTED_LIST_URLS__NO_ENS.map(async (url) => {
        return await (await fetch(url)).json()
      })
    ).then((response) => {
      setLogos(
        response
          .flatMap((item) => item.tokens)
          .reduce((logos, token) => {
            logos[isAddress(token.address)] = token.logoURI
            return logos
          }, {})
      )
    })
  }

  useEffect(() => {
    loadLogos()
  }, [])

  return <TokenLogosContext.Provider value={{ logos, loadLogos }}>{children}</TokenLogosContext.Provider>
}
